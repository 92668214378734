import AppConfig from '@/config/config';
import { atom } from 'jotai';
import {
  detailEnhancerDefaultValue,
  similarityStrengthDefaultValue,
  activeRatioDefaultValue,
  renderModeDefaultValue,
  customInputDefaultValue,
} from './defaultValues';
import { PictureTypes, PortraitSetTypes } from '@api-types';
import { atomWithStorage } from 'jotai/utils';

// Define the type for the individual portrait set data
interface PortraitSetData {
  detailEnhancer: number;
  similarityStrength: number;
  customInput: string | null;
  renderMode: string;
  activeRatio: string;
}

export const appearancehaircutAtom = atomWithStorage<string | null>('appearanceHaircut', null);
export const appearancehaircutNameAtom = atomWithStorage<string | null>('appearanceHaircutName', null);
export const appearanceHairColorAtom = atomWithStorage<string | null>('appearanceHairColor', null);
export const appearanceEyeColorAtom = atomWithStorage<string | null>('appearanceEyeColor', null);
export const appearanceStyleAtom = atomWithStorage<string | null>('appearanceStyle', null);
export const appearanceAgeAtom = atomWithStorage<number | null>('appearanceAge', null);

export const selectedHairColorAtom = atomWithStorage<string>('selectedHairColor', '');
export const selectedEyeColorAtom = atomWithStorage<string>('selectedEyeColor', '');

export const useBackgroundAtom = atom<boolean | null>(true);

// Atom to manage custom input state
export const customInputAtom = atomWithStorage<string | null>('customInput', customInputDefaultValue);

// Atom to manage detail enhancer state
export const detailEnhancerAtom = atomWithStorage<number>('detailEnhancer', detailEnhancerDefaultValue);

// Atom to manage similarity strength state
export const similarityStrengthAtom = atomWithStorage<number>('similarityStrength', similarityStrengthDefaultValue);

// Atom to manage active ratio state
export const activeRatioAtom = atomWithStorage<string>('activeRatio', activeRatioDefaultValue);

// Atom to manage render mode state
export const renderModeAtom = atomWithStorage<string>('renderMode', 'cinematic');

// Atom to manage the portrait set name state
export const portraitSetNameAtom = atom<string | null>(null);

// Atom to trigger refresh of menu styles
export const refreshMenuStylesAtom = atom<boolean>(false);

// Atom to manage the loading state during generation
export const generateLoadingAtom = atom<boolean>(false);

// Atom to manage the current generation counter state
export const currentGenerationCounterAtom = atom<PictureTypes.JobCounterViewModel>({
  activeJobsCount: 0,
  maxJobs: AppConfig.maxGenerationQueue,
});

// Atom to manage the portrait sets data state
export const portraitSetsDataState = atom<PortraitSetTypes.PortraitSetWithOptionsViewModel | null>(null);

// Atom to manage the state of updated menu items
export const updatedMenuItemsAtom = atom<{
  [key: string]: boolean;
}>({
  start: false,
  upload: false,
  discover: false,
  ratio: false,
  generate: false,
  details: false,
  styles: false,
});

// Atom to manage the state of style options
export const stylesOptionsAtom = atom<{
  [key: string]: boolean;
}>({
  save: false,
  load: false,
  restore: false,
  delete: false,
});

// Define the type for job results with payload
export type JobResultWithPayload = {
  jobResult: PictureTypes.ImageGenerationJobViewModel;
};

// Atom to manage the state of job results with payloads
export const jobResultsWithPayloadsAtom = atom<PictureTypes.PicturesResultViewModel | PictureTypes.PhotoEditorResultViewModel | null>(null);

// Atom to manage the state of the generate button's disabled status
export const generateButtonDisabledAtom = atom<boolean>(false);

// Atom to manage the text displayed on the generate button
export const generateButtonTextAtom = atom<string>('Generate');

// Atom to manage the link associated with the generate button
export const generateButtonLinkAtom = atom<string | undefined>(undefined);

// Atom to manage the state of the generate button's onClick disabled status
export const generateButtonOnClickDisabledAtom = atom<boolean>(false);

export const generationMenuUploadModalOpenAtom = atom<boolean>(false);

export const showFullScreenMobileBottomSheetAtom = atom<boolean>(false);

// EDIT PICTURES

export interface UploadedEditPicture {
  id: string;
  url: string;
  name: string;
}

export const uploadedEditPicturesAtom = atom<UploadedEditPicture[]>([]);

export enum EditMode {
  Freestyle = 'freestyle',
  PhotoEdit = 'photoEdit',
  Artist = 'artist',
}
export const editModeAtom = atomWithStorage<EditMode>('editMode', EditMode.Freestyle);
