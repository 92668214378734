import { env } from '@cuww/runtime-env';

const AppConfig = {
  apiDomain: env('NEXT_PUBLIC_API_DOMAIN', 'no-value'),
  appDomain: env('NEXT_PUBLIC_APP_DOMAIN', 'no-value'),
  pictureSetDomain: env('NEXT_PUBLIC_PICTURESET_DOMAIN', 'no-value'),
  picturesDomain: env('NEXT_PUBLIC_PICTURES_DOMAIN', 'no-value'),
  foundationPicturesDomain: env('NEXT_PUBLIC_FOUNDATION_PICTURES_DOMAIN', 'no-value'),
  betaMode: env('NEXT_PUBLIC_BETA_MODE', 'false'),
  cookieDomain: env('NEXT_PUBLIC_COOKIE_DOMAIN', 'no-value'),
  minUserPictures: Number(env('NEXT_PUBLIC_MIN_USER_PICTURES', '0')),
  maxUserPictures: Number(env('NEXT_PUBLIC_MAX_USER_PICTURES', '0')),
  cropResolution: env('NEXT_PUBLIC_SAVE_USER_CROP_RESOLUTION', 'no-value'),
  picturesPerGeneration: Number(env('NEXT_PUBLIC_PICTURES_PER_GENERATION', '0')),
  picturesPerPage: Number(env('NEXT_PUBLIC_PICTURES_PER_PAGE', '0')),
  maxGenerationQueue: Number(env('NEXT_PUBLIC_MAX_GENERATION_QUEUE', '5')),
  appleClientId: env('NEXT_PUBLIC_APPLE_CLIENT_ID', 'no-value'),
  appleAppBundleId: env('NEXT_PUBLIC_APPLE_APP_BUNDLE_ID', 'no-value'),
  appleAppSignInId: env('NEXT_PUBLIC_APPLE_APP_SIGNIN_ID', 'no-value'),
  appleAppSignInRedirectUrl: env('NEXT_PUBLIC_APPLE_APP_SIGNIN_REDIRECT_URI', 'no-value'),
  nudeBlurImage: env('NEXT_PUBLIC_NUDE_BLUR_IMAGE', '/assets/images/portraits/blur.jpg'),
  publicCategoryImages: env('NEXT_PUBLIC_ASSETS_IMAGES_PATH', '/assets/images/categories'),
  version: env('NEXT_PUBLIC_VERSION', 'dev'),
  revenueCatApiKey: env('NEXT_PUBLIC_REVENUECAT_API_KEY', 'no-value'),
};

export default AppConfig;
